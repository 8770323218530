@import "../../node_modules/ol/ol.css";

html,
body {
  padding: 0 !important;
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.ol-zoom {
  top: 80px;
}

.ol-attribution {
  bottom: 70px;
}

.ol-scale-line {
  bottom: 70px;
}
